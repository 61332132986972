<template>
  <div class="main_container">
    <MySwiper :count="list.length" title="Alert_AlertTitle">
      <swiper-slide
        class="swiper-slide"
        v-for="(item, index) in list"
        :key="index"
      >
        <HowToAlertContent
          v-if="item.type == '1'"
          :contents="item.contents"
          :isMmol="isMmol"
          :isAr="isAr"
        ></HowToAlertContent>
        <HowToAlertGlucoseContent
          v-if="item.type == '2'"
          :contents="item.contents"
          :title="item.title"
          :isMmol="isMmol"
          :isAr="isAr"
        />
      </swiper-slide>
    </MySwiper>
  </div>
</template>
  
<script setup>
import { ref, onMounted } from "vue";
import { SwiperSlide } from "swiper/vue";
import MySwiper from "../components/MySwiper.vue";
import HowToAlertContent from "../components/alert/HowToAlertContent.vue";
import HowToAlertGlucoseContent from "../components/alert/HowToAlertGlucoseContent.vue";
import { useRouter } from "vue-router";
const router = useRouter();
const isMmol = ref(false);
const isAr = ref(false);

onMounted(function () {
  var query = router.currentRoute.value.query || {}; // undefined
  isMmol.value = query["isMmol"] == 'true';
  console.log(isMmol.value);
  isAr.value = query["language"] == "ar";
});

const list = ref([
  {
    type: "1",
    contents: [
      {
        content: "Alert_PageOneContent",
        type: "text",
      },
      {
        content: require("@/assets/alert/alert_alarm.png"),
        type: "img",
        padding: true,
      },
    ],
  },
  {
    type: "1",
    contents: [
      {
        content: "Alert_PageTwoContent1",
        type: "text",
      },
      {
        type: "settings",
        settings: [
          {
            type: "normal",
            leftTitle: "Alert_Setting_Default",
            isShowRight: false,
            rightTitle: "",
            rightImage: require("@/assets/alert/alert_edit.png"),
            rightImageAr: require("@/assets/alert/alert_edit.png"),
          },
        ]
      },
      {
        content: "Alert_PageTwoContent2",
        type: "text",
      },
      {
        type: "settings",
        settings: [
          {
            type: "repeat",
            leftTitle: "Alert_Setting_Repeat",
          },
        ]
      },
    ],
  },
  {
    type: "1",
    contents: [
      {
        content: "Alert_PageThreeContent1",
        type: "text",
      },
      {
        type: "settings",
        settings: [
          {
            type: "normal",
            leftTitle: "Alert_Setting_AllDay",
            isShowRight: false,
            rightTitle: "",
            rightImage: require("@/assets/alert/alert_on.png"),
            rightImageAr: require("@/assets/alert/alert_on.png"),
          },
          {
            type: "normal",
            leftTitle: "Alert_Setting_UrgentLow",
            rightTitle: "",
            isShowRight: true,
            rightTitleMg: "Alert_Setting_Mg_54",
            rightTitleMmol: "Alert_Setting_Mmol_3",
            rightImage: require("@/assets/alert/alert_right.png"),
            rightImageAr: require("@/assets/alert/alert_left.png"),
          },
          {
            type: "normal",
            leftTitle: "Alert_Setting_Low",
            rightTitle: "",
            isShowRight: true,
            rightTitleMg: "Alert_Setting_Mg_70",
            rightTitleMmol: "Alert_Setting_Mmol_3_9",
            rightImage: require("@/assets/alert/alert_right.png"),
            rightImageAr: require("@/assets/alert/alert_left.png"),
          },
          {
            type: "normal",
            leftTitle: "Alert_Setting_High",
            rightTitle: "",
            isShowRight: true,
            rightTitleMg: "Alert_Setting_Mg_180",
            rightTitleMmol: "Alert_Setting_Mmol_10",
            rightImage: require("@/assets/alert/alert_right.png"),
            rightImageAr: require("@/assets/alert/alert_left.png"),
          },
        ]
      },
      {
        content: "Alert_PageThreeContent2",
        type: "text",
      },
      {
        type: "settings",
        settings: [
          {
            type: "normal",
            leftTitle: "Alert_Setting_AllDay",
            isShowRight: false,
            rightTitle: "",
            rightImage: require("@/assets/alert/alert_off.png"),
            rightImageAr: require("@/assets/alert/alert_off.png"),
          },
          {
            type: "normal",
            leftTitle: "Alert_Setting_Daytime",
            isShowRight: false,
            rightTitle: "",
            rightImage: require("@/assets/alert/alert_right.png"),
            rightImageAr: require("@/assets/alert/alert_left.png"),
          },
          {
            type: "normal",
            leftTitle: "Alert_Setting_Nighttime",
            isShowRight: false,
            rightTitle: "",
            rightImage: require("@/assets/alert/alert_right.png"),
            rightImageAr: require("@/assets/alert/alert_left.png"),
          },
        ]
      },
      {
        content: "Alert_PageThreeContent3",
        type: "text",
      },
      {
        type: "settings",
        settings: [
          {
            type: "normal",
            leftTitle: "Alert_Setting_Daytime",
            isShowRight: false,
            rightTitle: "",
            rightImage: require("@/assets/alert/alert_right.png"),
            rightImageAr: require("@/assets/alert/alert_left.png"),
          },
          {
            type: "normal",
            leftTitle: "Alert_Setting_Nighttime",
            isShowRight: false,
            rightTitle: "",
            rightImage: require("@/assets/alert/alert_right.png"),
            rightImageAr: require("@/assets/alert/alert_left.png"),
          },
          {
            type: "normal",
            leftTitle: "Alert_Setting_UrgentLow",
            rightTitle: "",
            isShowRight: true,
            rightTitleMg: "Alert_Setting_Mg_54",
            rightTitleMmol: "Alert_Setting_Mmol_3",
            rightImage: require("@/assets/alert/alert_right.png"),
            rightImageAr: require("@/assets/alert/alert_left.png"),
          },
          {
            type: "normal",
            leftTitle: "Alert_Setting_Low",
            rightTitle: "",
            isShowRight: true,
            rightTitleMg: "Alert_Setting_Mg_70",
            rightTitleMmol: "Alert_Setting_Mmol_3_9",
            rightImage: require("@/assets/alert/alert_right.png"),
            rightImageAr: require("@/assets/alert/alert_left.png"),
          },
          {
            type: "normal",
            leftTitle: "Alert_Setting_High",
            rightTitle: "",
            isShowRight: true,
            rightTitleMg: "Alert_Setting_Mg_180",
            rightTitleMmol: "Alert_Setting_Mmol_10",
            rightImage: require("@/assets/alert/alert_right.png"),
            rightImageAr: require("@/assets/alert/alert_left.png"),
          },
        ]
      },
    ],
  },
  {
    type: "2",
    title: "Alert_UrgentLowAlert",
    contents: [
      {
        contents: [
          {
            titleNo: "a.",
            content: "Alert_PageFourContent1",
          },
          {
            titleNo: "b.",
            content: "Alert_PageFourContent2",
          },
          {
            titleNo: "c.",
            content: "Alert_PageFourContent3",
          },
          {
            titleNo: "d.",
            content: "Alert_PageFourContent4",
          },
        ],
        type: "text",
      },
      {
        type: "settings",
        settings: [
          {
            type: "normal",
            leftTitle: "Alert_Setting_UrgentLow",
            isShowRight: false,
            rightTitle: "",
            rightImage: require("@/assets/alert/alert_on.png"),
            rightImageAr: require("@/assets/alert/alert_on.png"),
          },
          {
            type: "normal",
            leftTitle: "Alert_Setting_Level",
            rightTitle: "",
            isShowRight: true,
            rightTitleMg: "Alert_Setting_Mg_54",
            rightTitleMmol: "Alert_Setting_Mmol_3",
            rightImage: require("@/assets/alert/alert_right.png"),
            rightImageAr: require("@/assets/alert/alert_left.png"),
          },
          {
            type: "normal",
            leftTitle: "Alert_Setting_Interval",
            rightTitle: "Alert_Setting_Min_5",
            rightImage: require("@/assets/alert/alert_right.png"),
            rightImageAr: require("@/assets/alert/alert_left.png"),
          },
          {
            type: "normal",
            leftTitle: "Alert_Setting_Model",
            rightTitle: "Alert_Setting_System",
            rightImage: require("@/assets/alert/alert_right.png"),
            rightImageAr: require("@/assets/alert/alert_left.png"),
          },
        ]
      },
    ],
  },
  {
    type: "2",
    title: "Alert_LowAlert",
    contents: [
      {
        contents: [
          {
            titleNo: "a.",
            content: "Alert_PageFiveContent1",
          },
          {
            titleNo: "b.",
            content: "Alert_PageFiveContent2",
          },
          {
            titleNo: "c.",
            content: "Alert_PageFiveContent3",
          },
          {
            titleNo: "d.",
            content: "Alert_PageFiveContent4",
          },
        ],
        type: "text",
      },
      {
        type: "settings",
        settings: [
          {
            type: "normal",
            leftTitle: "Alert_Setting_Low",
            isShowRight: false,
            rightTitle: "",
            rightImage: require("@/assets/alert/alert_on.png"),
            rightImageAr: require("@/assets/alert/alert_on.png"),
          },
          {
            type: "normal",
            leftTitle: "Alert_Setting_Level",
            rightTitle: "",
            isShowRight: true,
            rightTitleMg: "Alert_Setting_Mg_70",
            rightTitleMmol: "Alert_Setting_Mmol_3_9",
            rightImage: require("@/assets/alert/alert_right.png"),
            rightImageAr: require("@/assets/alert/alert_left.png"),
          },
          {
            type: "normal",
            leftTitle: "Alert_Setting_Interval",
            rightTitle: "Alert_Setting_Min_5",
            rightImage: require("@/assets/alert/alert_right.png"),
            rightImageAr: require("@/assets/alert/alert_left.png"),
          },
          {
            type: "normal",
            leftTitle: "Alert_Setting_Model",
            rightTitle: "Alert_Setting_Vibration",
            rightImage: require("@/assets/alert/alert_right.png"),
            rightImageAr: require("@/assets/alert/alert_left.png"),
          },
        ]
      },
    ],
  },
  {
    type: "2",
    title: "Alert_HighAlert",
    contents: [
      {
        contents: [
          {
            titleNo: "a.",
            content: "Alert_PageSixContent1",
          },
          {
            titleNo: "b.",
            content: "Alert_PageSixContent2",
          },
          {
            titleNo: "c.",
            content: "Alert_PageSixContent3",
          },
          {
            titleNo: "d.",
            content: "Alert_PageSixContent4",
          },
          {
            titleNo: "e.",
            content: "Alert_PageSixContent5",
          },
        ],
        type: "text",
      },
      {
        type: "settings",
        settings: [
          {
            type: "normal",
            leftTitle: "Alert_Setting_High",
            isShowRight: false,
            rightTitle: "",
            rightImage: require("@/assets/alert/alert_on.png"),
            rightImageAr: require("@/assets/alert/alert_on.png"),
          },
          {
            type: "normal",
            leftTitle: "Alert_Setting_Level",
            rightTitle: "",
            isShowRight: true,
            rightTitleMg: "Alert_Setting_Mg_180",
            rightTitleMmol: "Alert_Setting_Mmol_10",
            rightImage: require("@/assets/alert/alert_right.png"),
            rightImageAr: require("@/assets/alert/alert_left.png"),
          },
          {
            type: "normal",
            leftTitle: "Alert_Setting_Interval",
            rightTitle: "Alert_Setting_Min_30",
            rightImage: require("@/assets/alert/alert_right.png"),
            rightImageAr: require("@/assets/alert/alert_left.png"),
          },
          {
            type: "normal",
            leftTitle: "Alert_Setting_DelayFirst",
            isShowRight: false,
            rightTitle: "",
            rightImage: require("@/assets/alert/alert_on.png"),
            rightImageAr: require("@/assets/alert/alert_on.png"),
          },
          {
            type: "normal",
            leftTitle: "Alert_Setting_Interval",
            rightTitle: "Alert_Setting_Min_30",
            rightImage: require("@/assets/alert/alert_right.png"),
            rightImageAr: require("@/assets/alert/alert_left.png"),
          },
          {
            type: "normal",
            leftTitle: "Alert_Setting_Model",
            rightTitle: "Alert_Setting_Vibration",
            rightImage: require("@/assets/alert/alert_right.png"),
            rightImageAr: require("@/assets/alert/alert_left.png"),
          },
        ]
      },
    ],
  },
  {
    type: "1",
    contents: [
      {
        content: "Alert_PageSevenContent1",
        type: "text",
      },

      {
        type: "settings",
        settings: [
          {
            type: "normal",
            leftTitle: "Alert_Setting_Default",
            isShowRight: false,
            rightTitle: "",
            rightImage: require("@/assets/alert/alert_edit.png"),
            rightImageAr: require("@/assets/alert/alert_edit.png"),
          },
          {
            type: "repeat",
            leftTitle: "Alert_Setting_Repeat",
          },
          {
            type: "normal",
            leftTitle: "Alert_Setting_AllDay",
            isShowRight: false,
            rightTitle: "",
            rightImage: require("@/assets/alert/alert_on.png"),
            rightImageAr: require("@/assets/alert/alert_on.png"),
          },
          {
            type: "normal",
            leftTitle: "Alert_Setting_UrgentLow",
            rightTitle: "",
            isShowRight: true,
            rightTitleMg: "Alert_Setting_Mg_54",
            rightTitleMmol: "Alert_Setting_Mmol_3",
            rightImage: require("@/assets/alert/alert_right.png"),
            rightImageAr: require("@/assets/alert/alert_left.png"),
          },
          {
            type: "normal",
            leftTitle: "Alert_Setting_Low",
            rightTitle: "",
            isShowRight: true,
            rightTitleMg: "Alert_Setting_Mg_70",
            rightTitleMmol: "Alert_Setting_Mmol_3_9",
            rightImage: require("@/assets/alert/alert_right.png"),
            rightImageAr: require("@/assets/alert/alert_left.png"),
          },
          {
            type: "normal",
            leftTitle: "Alert_Setting_High",
            rightTitle: "",
            isShowRight: true,
            rightTitleMg: "Alert_Setting_Mg_180",
            rightTitleMmol: "Alert_Setting_Mmol_10",
            rightImage: require("@/assets/alert/alert_right.png"),
            rightImageAr: require("@/assets/alert/alert_left.png"),
          },
        ]
      },
      {
        type: "add",
      },
      {
        content: "Alert_PageSevenContent2",
        type: "text",
      },
      {
        type: "settings",
        settings: [
          {
            type: "normal",
            leftTitle: "Alert_Setting_Default",
            isShowRight: false,
            rightTitle: "",
            rightImage: require("@/assets/alert/alert_edit.png"),
            rightImageAr: require("@/assets/alert/alert_edit.png"),
          },
          {
            type: "repeat",
            leftTitle: "Alert_Setting_Repeat",
          },
          {
            type: "normal",
            leftTitle: "Alert_Setting_AllDay",
            isShowRight: false,
            rightTitle: "",
            rightImage: require("@/assets/alert/alert_on.png"),
            rightImageAr: require("@/assets/alert/alert_on.png"),
          },
          {
            type: "normal",
            leftTitle: "Alert_Setting_UrgentLow",
            rightTitle: "",
            isShowRight: true,
            rightTitleMg: "Alert_Setting_Mg_54",
            rightTitleMmol: "Alert_Setting_Mmol_3",
            rightImage: require("@/assets/alert/alert_right.png"),
            rightImageAr: require("@/assets/alert/alert_left.png"),
          },
          {
            type: "normal",
            leftTitle: "Alert_Setting_Low",
            rightTitle: "",
            isShowRight: true,
            rightTitleMg: "Alert_Setting_Mg_70",
            rightTitleMmol: "Alert_Setting_Mmol_3_9",
            rightImage: require("@/assets/alert/alert_right.png"),
            rightImageAr: require("@/assets/alert/alert_left.png"),
          },
          {
            type: "normal",
            leftTitle: "Alert_Setting_High",
            rightTitle: "",
            isShowRight: true,
            rightTitleMg: "Alert_Setting_Mg_180",
            rightTitleMmol: "Alert_Setting_Mmol_10",
            rightImage: require("@/assets/alert/alert_right.png"),
            rightImageAr: require("@/assets/alert/alert_left.png"),
          },
        ]
      },
      {
        type: "settings",
        settings: [
          {
            type: "normal",
            leftTitle: "Alert_Setting_Default2",
            isShowRight: false,
            rightTitle: "",
            rightImage: require("@/assets/alert/alert_edit.png"),
            rightImageAr: require("@/assets/alert/alert_edit.png"),
          },
          {
            type: "repeat",
            leftTitle: "Alert_Setting_Repeat",
          },
          {
            type: "normal",
            leftTitle: "Alert_Setting_AllDay",
            isShowRight: false,
            rightTitle: "",
            rightImage: require("@/assets/alert/alert_off.png"),
            rightImageAr: require("@/assets/alert/alert_off.png"),
          },
          {
            type: "normal",
            leftTitle: "Alert_Setting_Daytime",
            isShowRight: false,
            rightTitle: "",
            rightImage: require("@/assets/alert/alert_right.png"),
            rightImageAr: require("@/assets/alert/alert_left.png"),
          },
          {
            type: "normal",
            leftTitle: "Alert_Setting_Nighttime",
            isShowRight: false,
            rightTitle: "",
            rightImage: require("@/assets/alert/alert_right.png"),
            rightImageAr: require("@/assets/alert/alert_left.png"),
          },
        ]
      },
    ],
  },
]);
  </script>
  
<style lang="scss" scoped>
.main_container {
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  height: 100vh;
  width: 100vw;
  .swiper-slide {
    overflow: scroll;
    .my-item {
      flex: 1;
      width: 100%;
      height: 100%;
    }
  }
}
</style>
  