export default {
	"主Key（勿动, 红色自动填充的为重复项，不可有）":"阿拉伯语Arabic(ar)-未上线",
	"Alert_PageOneContent":"سيكون لديك إجمالًا ملفان شخصيان متاحان لتناسب سيناريوهات مختلفة ، مثل الاجتماعات أو فترات أخرى من اليوم لضمان أفضل تجربة ممكنة.",
	"Alert_PageTwoContent1":"اضغط لتعديل اسم الملف الشخصي",
	"Alert_PageTwoContent2":"اضغط على الأيقونات أسفل \"التكرار\" لتحديد الأيام التي يكون فيها هذا الملف الشخصي فعالًا ، واضغط مرة أخرى لإلغاء التحديد.",
	"Alert_PageThreeContent1":"\"طوال اليوم\" تعني أن إعدادتك ستكون فعالة طوال اليوم.",
	"Alert_PageThreeContent2":"عند إيقافها ، يمكن تعديل التنبيهات حسب فترات النهار والليل.",
	"Alert_PageThreeContent3":"اضغط على \">\" سهم لتغيير فترة التنبيه لفترات النهار والليل.",
	"Alert_PageFourContent1":"يمكنك إيقاف تنبيه الانخفاض العاجل بإستخدام أزرار التبديل.",
	"Alert_PageFourContent2":"لا يمكن تعديل مستوى التنبيه ، سيقوم التطبيق بإشعارك عندما تكون قراءات الجلوكوز أقل من الإعدادات المحددة.",
	"Alert_PageFourContent3":"عند حدوث فترة طويلة نسبيًا من الانخفاض العاجل المستمر ، سيقوم التطبيق بإشعارك وفقًا للفواصل الزمنية المحددة.",
	"Alert_PageFourContent4":"يمكنك تخصيص أصوات التنبيه بإستخدام نغمات رنين مختلفة أو اهتزاز.",
	"Alert_PageFiveContent1":"يمكنك إيقاف تنبيه الانخفاض باستخدام زر التبديل.",
	"Alert_PageFiveContent2":"يمكن تغيير مستوى التنبيه; سيقوم التطبيق بإشعارك عندما تكون قراءات الجلوكوز أقل من الإعدادات المحددة.",
	"Alert_PageFiveContent3":"يمكنك تحديد فاصل التنبيهات. عند حدوث فترة طويلة نسبيًا من حدث انخفاض مستمر, سيقوم التطبيق بإشعارك وفقًا للفواصل الزمنية المحددة.",
	"Alert_PageFiveContent4":"يمكنك تخصيص أصوات التنبيه بإستخدام نغمات رنين مختلفة أو اهتزاز.",
	"Alert_PageSixContent1":"يمكنك إيقاف تنبيه الارتفاع باستخدام زر التبديل.",
	"Alert_PageSixContent2":"يمكن تغيير مستوى التنبيه; سيقوم التطبيق بإشعارك عندما تكون قراءات الجلوكوز أعلى من الإعدادات المحددة.",
	"Alert_PageSixContent3":"يمكنك تحديد فاصل التنبيهات. عند حدوث فترة طويلة نسبيًا من حدث ارتفاع مستمر, سيقوم التطبيق بإشعارك وفقًا للفواصل الزمنية المحددة.",
	"Alert_PageSixContent4":"يمكنك تأجيل تنبيه الارتفاع الأول يدويًا لفترة معينة. إذا استمر مستوى الجلوكوز في نطاق التنبيه العالي ستستأنف إشعارات التنبيه. على سبيل المثال سيقوم التطبيق بتفعيل التنبيهات بعد 20 دقيقة من الحدث الأول للارتفاع.",
	"Alert_PageSixContent5":"يمكنك أيضًا تخصيص أصوات التنبيه باستخدام نغمات رنين مختلفة أو اهتزاز.",
	"Alert_PageSevenContent1":"اضغط على \"إاضافة ملف تنبيه\" لإنشاء إعداد جديد سيؤثر على فترات زمنية مختلقة. لاحظ أنه إذا قمت بتحديد أيام متداخلة مدرجة في ملف آخر. ستقوم الإعدادات الجديدة باستبدال الإعدادات الحالية للأيام المعنية.",
	"Alert_PageSevenContent2":"تم إنشاء ملف جديد يمكنك تخصيص الفترات الفعالة للملفين.",
	"Alert_Setting_Model":"أصوات التنبيه",
	"Alert_Setting_DelayFirst":"تأجيل أول تنبيه ارتفاع",
	"Alert_Setting_Mg_54":"55 ملغم/دل",
	"Alert_AlertTitle":"تخصيص تنبيهات الجلوكوز الخاصة بك",
	"Alert_UrgentLowAlert":"تنبيه انخفاض عاجل ",
	"Alert_LowAlert":"تنبيه انخفاض ",
	"Alert_HighAlert":"تنبيه ارتفاع",
	"Monday_Abb":"الاثنين",
	"Tuesday_Abb":"الثلاثاء ",
	"Wednesday_Abb":"الاربعاء",
	"Thursday_Abb":"الخميس",
	"Friday_Abb":"الجمعة ",
	"Saturday_Abb":"السبت ",
	"Sunday_Abb":"الاحد",
	"Alert_Setting_AllDay":"كل الايام",
	"Alert_Setting_UrgentLow":"تنبيه انخفاض عاجل ",
	"Alert_Setting_Low":"تنبيه انخفاض ",
	"Alert_Setting_High":"تنبيه ارتفاع",
	"Alert_Setting_Daytime":"فترة النهار(08:00-20:00) ",
	"Alert_Setting_Nighttime":"فترة الليل(20:00-08:00)",
	"Alert_Setting_Interval":"فاصل",
	"Alert_Setting_Level":"المستوى",
	"Alert_Setting_Vibration":"اهتزاز",
	"Alert_Setting_Add":"إضافة ملف تبيه",
	"Alert_Setting_System":"النظام",
	"Alert_Setting_Mg_180":"180 ملغم/دل",
	"Alert_Setting_Mg_70":"70 ملغم/دل",
	"Alert_Setting_Mmol_10":"10 مليمول/لتر",
	"Alert_Setting_Mmol_3":"3.1 مليمول/لتر",
	"Alert_Setting_Mmol_3_9":"3.9 مليمول/لتر",
	"Alert_Setting_Default":"افتراضي",
	"Alert_Setting_Default2":"افتراضي 2",
	"Alert_Setting_Min_5":"5 دقائق",
	"Alert_Setting_Min_30":"30 دقيقة",
	"Alert_Setting_Repeat":"تكرار",
	"FAQ_FaqTitle1":"ما هو تطبيق ساي لينك؟",
	"FAQ_FaqTitle2":"ما الفرق بين ساي لينك و ساي تاج؟",
	"FAQ_FaqTitle3":"هل يجب علي اتخاذ قرارات علاجية بناءً على تطبيق ساي لينك؟",
	"FAQ_FaqTitle4":"كيف يعمل تطبيق ساي لينك ؟",
	"FAQ_FaqTitle5":"هل ستتأخر بيانات ساي لينك؟ ما مدة التأخير؟",
	"FAQ_FaqTitle6":"ما هي الإشعارات التي سأستقبلها؟",
	"FAQ_FaqTitle7":"كم عدد مستخدمي ساي تاج الذين يمكنني متابعتهم بإستخدام تطبيق ساي لينك؟",
	"FAQ_FaqTitle8":"عندما أقوم بإنشاء اتصال مع عدة مشاركين, معلومات من سيعرضها التطبيق في الواجهة الخاصة بي؟",
	"FAQ_FaqTitle9":"هل يمكنني رؤية البيانات التاريخية المشتركة؟",
	"FAQ_FaqTitle10":"ماذا تعني \"HI\"و \"LO\"؟",
	"FAQ_FaqTitle11":"لماذا تشير المنطقة الزرقاء في رسم الاتجاه؟",
	"FAQ_FaqTitle12":"ما علاقة نطاق الهدف بتنبيهات سكر الدم؟",
	"FAQ_FaqTitle13":"هل يمكنني الوصول إلى تطبيقات وخدمات أخرى باستخدام حساب ساي لينك الخاص بي؟",
	"FAQ_FaqTitle14":"ما هي المعلومات التي ستُعرض في مركز الرسائل؟",
	"FAQ_FaqContent1":"تطبيق ساي لينك هو تطبيق مصمم خصيصًا للأقارب, والأصدقاء, ومستخدمي ساي تاج الآخرين. يُمكنك من رؤية بيانات سكر الدم الحية, ومنحنى سكر الدم, وحالة الحساس لمستخدمي ساي تاج, وتلقي الإشعارات في حال حدوث أي خلل في مستوى السكر أو في حالات الطوارئ الأخرى.",
	"FAQ_FaqContent2":"تم تصميم تطبيق ساي لينك ليستخدمه العائلة, والأصدقاء, ومقدمي الرعاية. يمكنهم من رؤية بيانات الجلوكوز الحية, والتنبيهات على هواتفهم الذكية. بينما تم تصميم تطبيق ساي تاج ليستخدمه الأشخاص المصابون بالسكري لإجراء مراقبة دورية لمستوى الجلوكوز باستخدام الهاتف الذكي وجهاز ساي تاج.",
	"FAQ_FaqContent3":"البيانات والتحليلات المتعلقة بالجلوكوز التي يوفرها التطبيق يجب ألا تُستخدم كإحالات مباشرة للعلاج. قبل اتخاذ أي قرارات علاجية هامة, يُفضل إجراء فحوصات إضافية للتأكد, مثل اختبارات وخز الإصبع, لضمان تقديم العلاج الأمثل.",
	"FAQ_FaqContent4":"عند استلام تطبيق ساي تاج لبيانات الجلوكوز من الحساس, يقوم بنقل البيانات إلى الخادم يتيح تطبيق ساي تاج للمستخدم دعوة العائلة والأصدقاء ومقدمي الرعاية لمتابعة حالة الجلوكوز الخاصة بالمشارك. يمكن لهذه العائلة والأصدقاء ومقدمي الرعاية تثبيت تطبيق ساي لينك على هواتفهم, قبول دعوة المشاركة, ثم عرض بيانات الجلوكوز وتلقي تنبيهات الجلوكوز.",
	"FAQ_FaqContent5":"ستحدث تأخيرات في البيانات بسبب عوامل متعددة مثل تأخير الشبكة, والانقطاعات في الاتصال, وما إلى ذلك. في الظروف المثالية نسبيًا, يجب أن يكون التأخير أقل من بضع ثوان.",
	"FAQ_FaqContent6":"    أ. عندما تكون قراءة الجلوكوز للمريض أعلى/أو عند/أو أقل من مستويات التنبيه المحددة، مثل مستويات الجلوكوز العالية والمنخفضة والمنخفضة العاجلة. ب. عندما يكون مستوى الجلوكوز للمشارك خارج النطاقات المحددة مسبقًا، أي أقل من %n أو أعلى من %r. ج. عندما ينتهي المشارك من الجلسة الخاصة به/بها. د. عندما يتم حذف حساب المشارك. هـ. عندما يتوقف المشارك عن المشاركة. ف. عندما تكون هناك رسائل غير مقروءة. ز. عندما تكون هناك دعوات جديدة.\",",
	"FAQ_FaqContent7":"يمكن لحساب ساي لينك متابعة ما يصل إلى 10 مستخدمين لساي تاج. يمكن لحساب ساي تاج دعوة ما يصل إلى ما يصل إلى 10 مستخدمين لساي لينك.",
	"FAQ_FaqContent8":"نظرًا للقيود في وظيقة الواجهة. يمكننا عرض معلومات المشارك الأول فقط في قائمتك. يمكنك استخدام وظيفة \"تثبيت في الأعلى\" في صفحة الإعدادات لإعطاء الأولوية للمشارك المفضل.",
	"FAQ_FaqContent9":"يمكن للمُتابع عرض البيانات الحية وبيانات ال24 ساعة الماضية, ولكن لا يمكنه عرض البيانات السابقة لذلك. بالإضافة إلى ذلك, يمكنك فقط رؤية البيانات بعد قبول الدعوة, ولا يمكن عرض البيانات السابقة لهذه الفترة.",
	"FAQ_FaqContent10":"ال \"HI\" تشير إلى قراءة جلوكوز أعلى من %r. ال \"LO\" تشير إلى قراءة جلوكوز أقل من %n.",
	"FAQ_FaqContent11":"يمثل هذا النطاق الهدف الذي حدده المشارك في تطبيق ساي تاج الخاص به, ولا يمكنك تعديل هذا الإعداد.",
	"FAQ_FaqContent12":"النطاقين مستقلين ولا يؤثر كل منهما على الآخر. سيتم عرض النطاق الهدف فقط في رسم الاتجاه, الذي حدده المشارك في تطبيق ساي تاج, ولا يمكن تعديله في تطبيق ساي لينك. تنبيه الجلوكوز هو إشعار يُرسل إليك عندما يتجاوز مستوى الجلوكوز للمشارك النطاق الطبيعي. يمكن تعديل هذا الخيار في صفحة الإعدادات.",
	"FAQ_FaqContent13":"حساب ساي لينك الخاص بك هو في الواقع حساب ساي, يمكنك الوصول إلى جميع التطبيقات والخدمات للاستخدام غير المهني مثل تطبيق ساي تاج.",
	"FAQ_FaqContent14":"سيتم عرض جميع الإشعارات التي تتلقاها في مركز الرسائل. يمكنك أيضًا التحقق من الرسائل التي لم تتم معالجتها في مركز الرسائل: أ.الدعوات المعلقة. ب.عندما يتوفر تحديث جديد. ج.تنبيهات الجلوكوز لحالات الارتفاع/الانخفاض/الانخفاض العاجل."
}