<template>
  <router-view />
</template>
<script setup>
import { computed, watch } from "vue";
import { useRoute } from "vue-router";
import { useI18n } from "vue-i18n";
const { locale } = useI18n();
const route = useRoute();
const query = computed(() => route.query);
watch(query, (newQuery) => {
  console.log(newQuery);
  handleQuery(newQuery);
});
const handleQuery = (newQuery) => {
  var bodyDom = document.getElementById("app");
  var language = newQuery["language"];
  var isZh = language == "zh";
  var isAr = language == "ar";
  bodyDom.style["line-height"] = isZh ? "150%" : "120%";
  changeLang(language);
  if(isAr){
    document.documentElement.setAttribute("dir", "rtl");
  }
};
const changeLang = (lang) => {
  console.log("------app---lang=" + lang);
  locale.value = lang;
  localStorage.setItem("lang", lang);
};
</script>
<style lang="scss">
#app {
  background-color: #f5f7f9;
  height: 100vh;
  width: 100vw;
  margin: 0 !important;
  padding: 0 !important;
  white-space: pre-line;
}
html body {
  background-color: #f5f7f9;
  margin: 0 !important;
  padding: 0 !important;
  -webkit-text-size-adjust: none !important;
  font-family: SF Pro, PingFang SC;
}
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-weight: 510;
  -webkit-touch-callout: none; /*系统默认菜单被禁用*/
  -webkit-user-select: none; /*webkit浏览器*/
  -ms-user-select: none; /*IE10*/
  user-select: none;
  font-family: SF Pro, PingFang SC;
}
.title-large {
  font-style: normal;
  font-weight: 590;
  font-size: 18px;
  color: #18191b;
}
.title-medium {
  font-style: normal;
  font-weight: 590;
  font-size: 16px;
  color: #2e3033;
}
.body-medium {
  font-style: normal;
  font-weight: 510;
  font-size: 14px;
  color: #727478;
}
.body-small {
  font-style: normal;
  font-weight: 510;
  font-size: 12px;
  color: #727478;
}
span {
  @extend .title-small;
}
.span-small {
  @extend .body-small;
  color: #2e3033;
}
.span-medium {
  @extend .body-medium;
  color: #2e3033;
}
.title-small {
  font-weight: 590;
  font-size: 14px;
  color: #2e3033;
}
.row {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}
.top16 {
  margin-top: 16px;
}
.top12 {
  margin-top: 12px;
}
.top8 {
  margin-top: 8px;
}
.left18 {
  margin-left: 18px;
}
.right4 {
  margin-right: 4px;
}
.bottom8 {
  margin-bottom: 8px;
}
</style>
